<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 57%;display: flex;align-items: center;">
          <div>
            <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                size="large"
                @change="immediUpdate"
          />
          </div>
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search"
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
        <!-- 会员步数 -->
        <el-table-column
          label="步数"
          prop="step"
          align="center"
        >
        </el-table-column>
        <!-- 健步时间 -->
        <el-table-column 
          align="center" 
          label="健步时间" 
          prop="timestamp"
          :formatter="quantum"
        />
        <!-- 上传时间 -->
        <el-table-column 
          align="center" 
          label="上传时间" 
          prop="createTime"
          :formatter="quantum"
        />
      </el-table>
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[7, 14, 21, 27]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>
<script>
import {ref,onMounted,onActivated} from 'vue'
import { JBhistory } from '../../utils/api'
import qs from 'qs'
import moments from 'moment'
import { useRouter,useRoute } from "vue-router";
// import { Search} from "@element-plus/icons-vue";
// import { useRouter  } from "vue-router";
export default {
  name:"jbedit",
  setup() {
    const route = useRoute();
    // 时间转换
    let a =ref('')
    let b =ref('')

    // 时间清空监听
    const immediUpdate=(e)=>{ 
      if(e!==null){
        a.value=new Date(e[0]).getTime()/1000
        b.value=new Date(e[1]).getTime()/1000
      }else if(e==null){
        a.value=''
        b.value=''
      }
      console.log(e)
    }
    // 时间选择
    let value1=ref('')




    // 页得总条数
    const altogether=ref(null)
    // 加载中
    let texts =ref('加载中')
    // 时间转换
    let quantum=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
      }
    }
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.UserId
      if(translate.value==1){
        // approve.value=[]
        value1.value=''
        a.value=''
        b.value=''
        records()
      }
      // loading.value=true
      
    })
    onMounted(()=>{
      records()
    })
    // 搜索条件
    const search=()=>{
      let data ={
        username:sessionStorage.getItem('walking'),
        limit:branches.value,
        page:currentPage.value,
        startTime:a.value,
        endTime:b.value
      }
      JBhistory(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          filterTableData.value=res.data.data.page.list
          altogether.value=res.data.data.page.totalCount
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }

    // 分页
    let currentPage=ref(1)
    let pagesize=ref(7)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(a.value!==''||b.value!==''){
        search()
      }else{ 
        records()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(a.value!==''||b.value!==''){
        search()
      }else{
        records()
      }
    }
    // 历史步数接口
    let branches=ref(7)
    let records=()=>{
      let data ={
        username:sessionStorage.getItem('walking'),
        limit:branches.value,
        page:currentPage.value,
        startTime:'',
        endTime:''
      }
      JBhistory(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          filterTableData.value=res.data.data.page.list
          altogether.value=res.data.data.page.totalCount
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 设置按钮弹窗
    let standard =ref(false)
    // 路由跳转
    // const router = useRouter()
    // 列表数据循环
    const filterTableData = ref([])
    return {
      immediUpdate,
      value1,
      search,
      handleCurrentChange,
      handleSizeChange,
      altogether,
      branches,
      pagesize,
      currentPage,
      // 加载中
      texts,
      // 时间转换
      quantum,
      // 设置按钮弹窗
      standard,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    
  },
};
</script>
<style lang="scss" scoped>
.hunt {
  width: 22%;
  margin-right: 10px;
  margin-left: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 170px);
  overflow: auto;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.el-button+.el-button{
  margin-left: 70px;
}
.default{
  color: #909399;
  margin-left: 10px;
}
.designation {
  font-size: 15px;
  font-weight: bold;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-bottom: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
